import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * @desc view containing main website informations
 */
@Component({
  components: {

  }
})
export default class HomeView extends Vue {

}